<template>
    <div v-if="comboProducts">
        <h2 class="h6 text-bold text-default-5 text-uppercase font-roboto-c mt mb">
            O que comprar junto:
        </h2>
        <div class="product-combo-desktop hidden-sm-down" style="gap: 0.5rem">
            <div v-for="(product, index) in comboProducts" style="width: 100%; max-width: 305px">
                <div class="d-flex">
                    <div
                        style="width: 100%"
                        :style="{
                            opacity:
                                selectedProducts &&
                                selectedProducts.some((p) => p.id === product.id)
                                    ? 1
                                    : 0.5,
                        }"
                    >
                        <div
                            id="product-list"
                            class="product-combo-item pos-relative"
                            @click="toggleSelectedProduct(product, index)"
                            :style="{
                                backgroundImage: `url(${getProductImage(product)}`,
                            }"
                        >
                            <span
                                :class="{
                                    'selected icon-ok':
                                        selectedProducts &&
                                        selectedProducts.some((p) => p.id === product.id),
                                }"
                                class="icon-cr icon-select pos-absolute cursor-pointer border-default-5 border-solid border"
                            ></span>
                            <span
                                v-if="index < comboProducts.length - 1"
                                class="icon-cr icon-combo pos-absolute"
                            ></span>
                        </div>
                        <div class="d-block">
                            <a :href="product.link">
                                <h4
                                    class="product-list-item-title"
                                    style="font-size: 1rem"
                                    itemprop="name"
                                >
                                    {{ product.name }}
                                </h4>
                            </a>
                            <template v-if="product && product.in_stock">
                                <ProductCatalogPrice
                                    class="product-combo-price"
                                    :product="product"
                                />
                            </template>
                            <template v-if="product">
                                <ProductSelectVariations
                                    :product="product"
                                    @input="handleSelectedVariation($event, index)"
                                    :shop-mode="true"
                                    :disabled="!selectedProducts.some((p) => p.id === product.id)"
                                >
                                </ProductSelectVariations>
                            </template>
                        </div>
                    </div>
                    <div style="width: 5px">
                        <i
                            class="icon-cr icon-combo-plus"
                            :style="{
                                display: selectedProducts && index < 2 ? 'block' : 'none',
                            }"
                        />
                    </div>
                </div>
            </div>
            <div class="text-uppercase bg-product-combo">
                <h3
                    style="font-size: 1rem; line-height: 16.41px; font-weight: bolder"
                    class="h4"
                    v-if="selectedProducts.length > 0"
                >
                    Compre <span v-if="countSelected > 1">os</span>
                    <span v-if="countSelected">{{ countSelected }}</span> por
                </h3>
                <h3
                    style="font-size: 1rem; line-height: 16.41px; font-weight: bolder"
                    class="h4"
                    v-else
                >
                    Selecione ao menos 1 produto
                </h3>
                <div
                    v-if="
                        comboTotalPrice &&
                        comboTotalPrice < fullPrice &&
                        selectedProducts.length > 2
                    "
                >
                    <h3 class="combo-price-old h4">
                        <span class="line-through">{{ $f.formatMoney(fullPrice) }}</span>
                    </h3>
                    <h3 class="combo-price h4">
                        {{ $f.formatMoney(comboTotalPrice) }}
                    </h3>
                    <p class="text-small">*caso sua sacola seja válida para a promoção</p>
                </div>
                <p v-else-if="selectedProducts.length > 0" class="combo-price h4">
                    {{ $f.formatMoney(fullPrice) }}
                </p>
                <span class="product-combo-cashback">
                    <i class="icon-cr icon-cashback mr-1 text-cashback" style="font-size: 20px" />
                    <span v-if="cashback > 0"
                        >ganhe até {{ $f.formatMoney(cashback) }} de cashback</span
                    >
                    <span v-else>ganhe cashback</span>
                    <i
                        class="icon-cr icon-info cursor-pointer"
                        v-tooltip="`O valor pode variar se houver desconto adicional em sua sacola`"
                    ></i>
                </span>
                <p v-if="error" class="text-danger">
                    {{ error }}
                </p>
                <button
                    class="d-flex btn btn-lg btn-wide text-uppercase mt-3"
                    style="place-content: center; min-width: 290px"
                    @click="handleAddToCart"
                >
                    <span v-if="!loadingCart">Comprar Junto</span>
                    <div v-else class="my-2 mx cr-spinner-small"></div>
                </button>
            </div>
        </div>
        <div class="product-combo-mobile hidden-sm-up">
            <div v-for="(product, index) in comboProducts">
                <div
                    class="d-flex"
                    style="gap: 1rem"
                    :style="{
                        opacity:
                            selectedProducts && selectedProducts.some((p) => p.id === product.id)
                                ? 1
                                : 0.5,
                    }"
                >
                    <div
                        class="product-combo-mobile-item pos-relative"
                        :style="{
                            backgroundImage: `url(${getProductImage(product)}`,
                        }"
                        style="width: 100%; max-width: 202px"
                    >
                        <span
                            :class="{
                                'selected icon-ok':
                                    selectedProducts &&
                                    selectedProducts.some((p) => p.id === product.id),
                            }"
                            class="icon-cr icon-select pos-absolute cursor-pointer border-2 border-default-5 border-solid border"
                            @click="toggleSelectedProduct(product, index)"
                        ></span>
                        <span
                            v-if="index < comboProducts.length - 1"
                            class="icon-cr icon-combo icon-combo-mobile pos-absolute"
                        ></span>
                    </div>
                    <div class="flex-item-grow">
                        <a :href="product.link">
                            <h4
                                class="product-list-item-title w-full"
                                style="font-size: 1.25rem"
                                itemprop="name"
                            >
                                {{ product.name }}
                            </h4>
                        </a>
                        <template v-if="product && product.in_stock">
                            <ProductCatalogPrice class="product-combo-price" :product="product" />
                        </template>
                        <template v-if="product">
                            <ProductSelectVariations
                                :product="product"
                                @input="handleSelectedVariation($event, index)"
                                :shop-mode="true"
                                :disabled="!selectedProducts.some((p) => p.id === product.id)"
                            >
                            </ProductSelectVariations>
                        </template>
                    </div>
                </div>
                <div style="height: 2px">
                    <i
                        class="icon-cr icon-combo-plus"
                        :style="{
                            display: selectedProducts && index < 2 ? 'block' : 'none',
                        }"
                    />
                </div>
            </div>
            <div class="text-uppercase bg-product-combo-mobile">
                <h3
                    style="font-size: 1rem; line-height: 16.41px; font-weight: bolder"
                    class="h4"
                    v-if="selectedProducts.length > 1"
                >
                    Compre <span v-if="countSelected > 1">os</span>
                    <span v-if="countSelected">{{ countSelected }}</span> por
                </h3>
                <h3
                    style="font-size: 1rem; line-height: 16.41px; font-weight: bolder"
                    class="h4"
                    v-else
                >
                    Selecione ao menos 1 produto
                </h3>
                <div
                    v-if="
                        comboTotalPrice &&
                        comboTotalPrice < fullPrice &&
                        selectedProducts.length > 2
                    "
                >
                    <h3 class="combo-price-old h4">
                        <span class="line-through">{{ $f.formatMoney(fullPrice) }}</span>
                    </h3>
                    <h3 class="combo-price h4">
                        {{ $f.formatMoney(comboTotalPrice) }}
                    </h3>
                    <p class="text-small">*caso sua sacola seja válida para a promoção</p>
                </div>
                <p v-else class="combo-price h4">{{ $f.formatMoney(fullPrice) }}</p>
                <span class="product-combo-cashback">
                    <i class="icon-cr icon-cashback mr-1 text-cashback" style="font-size: 20px" />
                    ganhe até {{ $f.formatMoney(cashback) }} de cashback
                    <i
                        class="icon-cr icon-info cursor-pointer"
                        v-tooltip="`O valor pode variar se houver desconto adicional em sua sacola`"
                    ></i>
                </span>
                <p v-if="error" class="text-danger">
                    {{ error }}
                </p>
                <button
                    class="d-flex btn btn-lg btn-wide text-uppercase mt-3"
                    style="place-content: center; min-width: 290px"
                    @click="handleAddToCart"
                >
                    <span v-if="!loadingCart">Comprar Junto</span>
                    <div v-else class="my-2 mx cr-spinner-small"></div>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    import ProductCatalogPrice from './ProductCatalogPrice.vue';
    import ProductVariations from './ProductVariations.vue';
    import ProductSelectVariations from './ProductSelectVariations.vue';
    import { productService } from '../../../common/service/resource';
    import RequestsEmitter from '../../../../../submodules/src/components/util/requests-emitter/RequestsEmitter.vue';
    import { cartStore } from '../../common/store/cart-store';
    import { appendQueryString } from '../../../common/common';

    export default {
        name: 'ProductCombo',

        mixins: [RequestsEmitter],

        components: { ProductSelectVariations, ProductVariations, ProductCatalogPrice },

        props: {
            product: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                comboProducts: null,
                productIndex: null,
                productVariation: null,
                selectedProducts: [],
                fullPrice: 0,
                cashback: 0,
                loadingCart: false,
                error: null,
                comboTotalPrice: null,
            };
        },

        computed: {
            countSelected() {
                return this.selectedProducts ? this.selectedProducts.length : 0;
            },
        },

        created() {
            this.getComboProducts();
        },

        methods: {
            updatePrice() {
                let price = 0;
                let cashback = 0;
                let price_old = 0;

                if (this.selectedProducts && this.selectedProducts.length > 0) {
                    this.selectedProducts.forEach((product) => {
                        cashback += product.price * (product.cashback_percentage / 100);
                    });

                    this.selectedProducts.forEach((product) => {
                        if (!product.is_clothing && product.variation) {
                            price += product.variation.price;
                            price_old += product.variation.price_old ?? product.variation.price;
                        } else {
                            price += product.price;
                            price_old += product.price_old ?? product.price;
                        }
                    });
                }

                this.fullPrice = Math.round(parseFloat(price) * 10) / 10;
                this.cashback = cashback;
            },

            getProductImage(product) {
                return appendQueryString(product.img_cover, {
                    auto: 'compress,format',
                    q: 65,
                    fit: 'crop',
                    w: 290,
                    h: 435,
                });
            },

            getComboProducts() {
                this.exposedRequests++;
                productService
                    .productCombo(this.product.id)
                    .then((response) => {
                        if (!response.data) {
                            return;
                        }
                        this.selectedProducts = response.data.products;
                        if (response.data.offer && response.data.offer.total) {
                            this.comboTotalPrice = response.data.offer.total;
                        }
                        this.selectedProducts.forEach(function (product) {
                            product.variation = null;
                        });
                        this.comboProducts = Object.assign({}, this.selectedProducts);
                    })
                    .catch((error) => console.error(error))
                    .then(() => this.exposedRequests--);
            },

            handleAddToCart() {
                if (!this.selectedProducts.length > 0) {
                    return;
                }
                let variations = [];
                this.error = null;
                this.selectedProducts.forEach(function (product) {
                    if (product.variation) {
                        variations.push(product.variation);
                    }
                });
                if (variations.length < this.selectedProducts.length) {
                    this.error = 'Escolha um tamanho para cada um dos produtos selecionados';
                }
                this.bulkAddToCart(this.selectedProducts);
            },

            handleSelectedVariation(variation, index) {
                let productIndex = this.selectedProducts.findIndex(
                    (p) => p.id === this.comboProducts[index].id
                );

                if (variation) {
                    this.selectedProducts[productIndex].variation = variation;
                }

                this.updatePrice();
            },

            toggleSelectedProduct(product) {
                const productIndex = this.selectedProducts.findIndex((p) => p.id === product.id);
                if (productIndex !== -1) {
                    this.selectedProducts.splice(productIndex, 1);
                } else {
                    this.selectedProducts = [...this.selectedProducts, product];
                }

                this.updatePrice();
            },

            bulkAddToCart(products) {
                this.error = null;
                const cartBodyRequest = this.getCartBodyRequest(products);
                if (cartBodyRequest && !this.error) {
                    this.loadingCart = true;
                    cartStore
                        .addProduct(cartBodyRequest, true)
                        .then((response) => {
                            this.$scope.cart_id = response.data.cart_id;
                        })
                        .catch((error) => {
                            this.error =
                                'Erro ao adicionar ao carrinho, recarregue a página ou tente novamente';
                            this.loadingCart = false;
                        })
                        .then(() => {
                            this.loadingCart = false;
                        });
                }
            },

            getCartBodyRequest(selectedProducts) {
                const products = [];
                selectedProducts.forEach((product) => {
                    if (!product.variation) {
                        this.error = 'Escolha um tamanho para cada um dos produtos selecionados';
                        this.loadingCart = false;
                        return;
                    }
                    if (product.variation) {
                        products.push({
                            product_id: product.id,
                            variation_id: product.variation.variation_id,
                            quantity: 1,
                        });
                    }
                });
                const data = new FormData();
                data.append('json', '1');
                data.append('products', JSON.stringify(products));
                // data.append('fb_event_id', this.facebookEventId);
                return data;
            },
        },
    };
</script>
