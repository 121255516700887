<template>
    <div id="contador-timer-clock">
        <div class="clock-section" id="hours">
            <span
                class="clock-number"
                :style="{
                    'background-color': counterBackgroundColor,
                    color: counterTextColor,
                }"
                >{{ hours >= 10 ? hours.toString().substring(0, 1) : 0 }}</span
            >
            <span
                class="clock-number"
                :style="{
                    'background-color': counterBackgroundColor,
                    color: counterTextColor,
                }"
                >{{
                    hours >= 10
                        ? hours.toString().substring(1, 2)
                        : hours.toString().substring(0, 1)
                }}</span
            >
            <span
                class="clock-marker"
                :style="{
                    color: counterTextColor,
                }"
                >h</span
            >
        </div>
        <div class="clock-section" id="minutes">
            <span
                class="clock-number"
                :style="{
                    'background-color': counterBackgroundColor,
                    color: counterTextColor,
                }"
                >{{ minutes >= 10 ? minutes.toString().substring(0, 1) : 0 }}</span
            >
            <span
                class="clock-number"
                :style="{
                    'background-color': counterBackgroundColor,
                    color: counterTextColor,
                }"
                >{{
                    minutes >= 10
                        ? minutes.toString().substring(1, 2)
                        : minutes.toString().substring(0, 1)
                }}</span
            >
            <span
                class="clock-marker"
                :style="{
                    color: counterTextColor,
                }"
                >m</span
            >
        </div>
        <div class="clock-section" id="seconds">
            <span
                class="clock-number"
                :style="{
                    'background-color': counterBackgroundColor,
                    color: counterTextColor,
                }"
                >{{ seconds >= 10 ? seconds.toString().substring(0, 1) : 0 }}</span
            >
            <span
                class="clock-number"
                :style="{
                    'background-color': counterBackgroundColor,
                    color: counterTextColor,
                }"
                >{{
                    seconds >= 10
                        ? seconds.toString().substring(1, 2)
                        : seconds.toString().substring(0, 1)
                }}</span
            >
            <span
                class="clock-marker"
                :style="{
                    color: counterTextColor,
                }"
                >s</span
            >
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Counter',
        props: {
            deadline: Date,
            showDays: Boolean,
            counterTextColor: null,
            counterBackgroundColor: null,
        },
        data() {
            return {
                realDeadline: null, // usado quando a data final do contador é diferente da data final da oferta
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        },
        watch: {
            deadline: {
                immediate: true,
                handler(date) {
                    if (date) {
                        this.realDeadline = date.getTime();
                    }
                },
            },
        },
        mounted() {
            this.update();
            setInterval(this.update.bind(this), 1000);
        },

        methods: {
            getValues() {
                const now = new Date();
                const diff = this.realDeadline ? this.realDeadline - now.getTime() : 0;

                if (diff < 0) {
                    return {
                        hours: 0,
                        minutes: 0,
                        seconds: 0,
                    };
                }

                const hours = Math.floor(diff / (60 * 60 * 1000));

                return {
                    days: Math.floor(diff / (60 * 60 * 1000 * 24)),
                    hours: this.showDays ? hours % 24 : hours,
                    minutes: Math.floor(diff / (60 * 1000)) % 60,
                    seconds: Math.floor(diff / 1000) % 60,
                };
            },
            update() {
                const values = this.getValues();
                this.days = values.days;
                this.hours = values.hours;
                this.minutes = values.minutes;
                this.seconds = values.seconds;
            },
        },
    };
</script>
