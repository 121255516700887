var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.comboProducts)?_c('div',[_c('h2',{staticClass:"h6 text-bold text-default-5 text-uppercase font-roboto-c mt mb"},[_vm._v("\n        O que comprar junto:\n    ")]),_vm._v(" "),_c('div',{staticClass:"product-combo-desktop hidden-sm-down",staticStyle:{"gap":"0.5rem"}},[_vm._l((_vm.comboProducts),function(product,index){return _c('div',{staticStyle:{"width":"100%","max-width":"305px"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticStyle:{"width":"100%"},style:({
                        opacity:
                            _vm.selectedProducts &&
                            _vm.selectedProducts.some((p) => p.id === product.id)
                                ? 1
                                : 0.5,
                    })},[_c('div',{staticClass:"product-combo-item pos-relative",style:({
                            backgroundImage: `url(${_vm.getProductImage(product)}`,
                        }),attrs:{"id":"product-list"},on:{"click":function($event){return _vm.toggleSelectedProduct(product, index)}}},[_c('span',{staticClass:"icon-cr icon-select pos-absolute cursor-pointer border-default-5 border-solid border",class:{
                                'selected icon-ok':
                                    _vm.selectedProducts &&
                                    _vm.selectedProducts.some((p) => p.id === product.id),
                            }}),_vm._v(" "),(index < _vm.comboProducts.length - 1)?_c('span',{staticClass:"icon-cr icon-combo pos-absolute"}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"d-block"},[_c('a',{attrs:{"href":product.link}},[_c('h4',{staticClass:"product-list-item-title",staticStyle:{"font-size":"1rem"},attrs:{"itemprop":"name"}},[_vm._v("\n                                "+_vm._s(product.name)+"\n                            ")])]),_vm._v(" "),(product && product.in_stock)?[_c('ProductCatalogPrice',{staticClass:"product-combo-price",attrs:{"product":product}})]:_vm._e(),_vm._v(" "),(product)?[_c('ProductSelectVariations',{attrs:{"product":product,"shop-mode":true,"disabled":!_vm.selectedProducts.some((p) => p.id === product.id)},on:{"input":function($event){return _vm.handleSelectedVariation($event, index)}}})]:_vm._e()],2)]),_vm._v(" "),_c('div',{staticStyle:{"width":"5px"}},[_c('i',{staticClass:"icon-cr icon-combo-plus",style:({
                            display: _vm.selectedProducts && index < 2 ? 'block' : 'none',
                        })})])])])}),_vm._v(" "),_c('div',{staticClass:"text-uppercase bg-product-combo"},[(_vm.selectedProducts.length > 0)?_c('h3',{staticClass:"h4",staticStyle:{"font-size":"1rem","line-height":"16.41px","font-weight":"bolder"}},[_vm._v("\n                Compre "),(_vm.countSelected > 1)?_c('span',[_vm._v("os")]):_vm._e(),_vm._v(" "),(_vm.countSelected)?_c('span',[_vm._v(_vm._s(_vm.countSelected))]):_vm._e(),_vm._v(" por\n            ")]):_c('h3',{staticClass:"h4",staticStyle:{"font-size":"1rem","line-height":"16.41px","font-weight":"bolder"}},[_vm._v("\n                Selecione ao menos 1 produto\n            ")]),_vm._v(" "),(
                    _vm.comboTotalPrice &&
                    _vm.comboTotalPrice < _vm.fullPrice &&
                    _vm.selectedProducts.length > 2
                )?_c('div',[_c('h3',{staticClass:"combo-price-old h4"},[_c('span',{staticClass:"line-through"},[_vm._v(_vm._s(_vm.$f.formatMoney(_vm.fullPrice)))])]),_vm._v(" "),_c('h3',{staticClass:"combo-price h4"},[_vm._v("\n                    "+_vm._s(_vm.$f.formatMoney(_vm.comboTotalPrice))+"\n                ")]),_vm._v(" "),_c('p',{staticClass:"text-small"},[_vm._v("*caso sua sacola seja válida para a promoção")])]):(_vm.selectedProducts.length > 0)?_c('p',{staticClass:"combo-price h4"},[_vm._v("\n                "+_vm._s(_vm.$f.formatMoney(_vm.fullPrice))+"\n            ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"product-combo-cashback"},[_c('i',{staticClass:"icon-cr icon-cashback mr-1 text-cashback",staticStyle:{"font-size":"20px"}}),_vm._v(" "),(_vm.cashback > 0)?_c('span',[_vm._v("ganhe até "+_vm._s(_vm.$f.formatMoney(_vm.cashback))+" de cashback")]):_c('span',[_vm._v("ganhe cashback")]),_vm._v(" "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(`O valor pode variar se houver desconto adicional em sua sacola`),expression:"`O valor pode variar se houver desconto adicional em sua sacola`"}],staticClass:"icon-cr icon-info cursor-pointer"})]),_vm._v(" "),(_vm.error)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                "+_vm._s(_vm.error)+"\n            ")]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"d-flex btn btn-lg btn-wide text-uppercase mt-3",staticStyle:{"place-content":"center","min-width":"290px"},on:{"click":_vm.handleAddToCart}},[(!_vm.loadingCart)?_c('span',[_vm._v("Comprar Junto")]):_c('div',{staticClass:"my-2 mx cr-spinner-small"})])])],2),_vm._v(" "),_c('div',{staticClass:"product-combo-mobile hidden-sm-up"},[_vm._l((_vm.comboProducts),function(product,index){return _c('div',[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"1rem"},style:({
                    opacity:
                        _vm.selectedProducts && _vm.selectedProducts.some((p) => p.id === product.id)
                            ? 1
                            : 0.5,
                })},[_c('div',{staticClass:"product-combo-mobile-item pos-relative",staticStyle:{"width":"100%","max-width":"202px"},style:({
                        backgroundImage: `url(${_vm.getProductImage(product)}`,
                    })},[_c('span',{staticClass:"icon-cr icon-select pos-absolute cursor-pointer border-2 border-default-5 border-solid border",class:{
                            'selected icon-ok':
                                _vm.selectedProducts &&
                                _vm.selectedProducts.some((p) => p.id === product.id),
                        },on:{"click":function($event){return _vm.toggleSelectedProduct(product, index)}}}),_vm._v(" "),(index < _vm.comboProducts.length - 1)?_c('span',{staticClass:"icon-cr icon-combo icon-combo-mobile pos-absolute"}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex-item-grow"},[_c('a',{attrs:{"href":product.link}},[_c('h4',{staticClass:"product-list-item-title w-full",staticStyle:{"font-size":"1.25rem"},attrs:{"itemprop":"name"}},[_vm._v("\n                            "+_vm._s(product.name)+"\n                        ")])]),_vm._v(" "),(product && product.in_stock)?[_c('ProductCatalogPrice',{staticClass:"product-combo-price",attrs:{"product":product}})]:_vm._e(),_vm._v(" "),(product)?[_c('ProductSelectVariations',{attrs:{"product":product,"shop-mode":true,"disabled":!_vm.selectedProducts.some((p) => p.id === product.id)},on:{"input":function($event){return _vm.handleSelectedVariation($event, index)}}})]:_vm._e()],2)]),_vm._v(" "),_c('div',{staticStyle:{"height":"2px"}},[_c('i',{staticClass:"icon-cr icon-combo-plus",style:({
                        display: _vm.selectedProducts && index < 2 ? 'block' : 'none',
                    })})])])}),_vm._v(" "),_c('div',{staticClass:"text-uppercase bg-product-combo-mobile"},[(_vm.selectedProducts.length > 1)?_c('h3',{staticClass:"h4",staticStyle:{"font-size":"1rem","line-height":"16.41px","font-weight":"bolder"}},[_vm._v("\n                Compre "),(_vm.countSelected > 1)?_c('span',[_vm._v("os")]):_vm._e(),_vm._v(" "),(_vm.countSelected)?_c('span',[_vm._v(_vm._s(_vm.countSelected))]):_vm._e(),_vm._v(" por\n            ")]):_c('h3',{staticClass:"h4",staticStyle:{"font-size":"1rem","line-height":"16.41px","font-weight":"bolder"}},[_vm._v("\n                Selecione ao menos 1 produto\n            ")]),_vm._v(" "),(
                    _vm.comboTotalPrice &&
                    _vm.comboTotalPrice < _vm.fullPrice &&
                    _vm.selectedProducts.length > 2
                )?_c('div',[_c('h3',{staticClass:"combo-price-old h4"},[_c('span',{staticClass:"line-through"},[_vm._v(_vm._s(_vm.$f.formatMoney(_vm.fullPrice)))])]),_vm._v(" "),_c('h3',{staticClass:"combo-price h4"},[_vm._v("\n                    "+_vm._s(_vm.$f.formatMoney(_vm.comboTotalPrice))+"\n                ")]),_vm._v(" "),_c('p',{staticClass:"text-small"},[_vm._v("*caso sua sacola seja válida para a promoção")])]):_c('p',{staticClass:"combo-price h4"},[_vm._v(_vm._s(_vm.$f.formatMoney(_vm.fullPrice)))]),_vm._v(" "),_c('span',{staticClass:"product-combo-cashback"},[_c('i',{staticClass:"icon-cr icon-cashback mr-1 text-cashback",staticStyle:{"font-size":"20px"}}),_vm._v("\n                ganhe até "+_vm._s(_vm.$f.formatMoney(_vm.cashback))+" de cashback\n                "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(`O valor pode variar se houver desconto adicional em sua sacola`),expression:"`O valor pode variar se houver desconto adicional em sua sacola`"}],staticClass:"icon-cr icon-info cursor-pointer"})]),_vm._v(" "),(_vm.error)?_c('p',{staticClass:"text-danger"},[_vm._v("\n                "+_vm._s(_vm.error)+"\n            ")]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"d-flex btn btn-lg btn-wide text-uppercase mt-3",staticStyle:{"place-content":"center","min-width":"290px"},on:{"click":_vm.handleAddToCart}},[(!_vm.loadingCart)?_c('span',[_vm._v("Comprar Junto")]):_c('div',{staticClass:"my-2 mx cr-spinner-small"})])])],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }