<template>
    <div class="success-form mt-3">
        <p class="alert-warning">Sua solicitação não foi enviada. Clique abaixo para tentar novamente.</p>
        <button class="btn" id="btn-return-form" @click="returnToForm">Voltar</button>
    </div>
</template>

<script>
    export default {
        name: 'ErrorFormMessage',
        props: {
            returnToForm: {
                type: Function,
                default: () => {},
            },
        },
    };
</script>
