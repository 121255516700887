<template>
    <div id="product-variations">
        <div
            v-if="
                !product.is_clothing &&
                sizes &&
                (sizes.length > 1 || (hasFastDelivery && onlyDeliveryReady && originalSizes > 1))
            "
            class="text-yanone"
        >
            <SelectField
                v-if="isPhone"
                v-model="selectedPhoneModel"
                :label="$t('_product.manufacturer')"
            >
                <option :value="null">
                    {{ $t('_product.selectManufacturer') }}
                </option>
                <option v-for="model in phoneModels" :value="model">
                    {{ model }}
                </option>
            </SelectField>
            <SelectField
                :disabled="isPhone && !selectedPhoneModel"
                :label="label ? label : isPhone ? $t('_product.phoneModel') : ''"
                class="mt-3"
                :model-value="selectedVariation"
                @change="$emit('input', $event)"
            >
                <option v-if="isPhone" :value="null">
                    {{ $t('_product.selectPhoneModel') }}
                </option>
                <option v-else :value="null">
                    {{ $t('_product.selectSize') }}
                </option>
                <option
                    v-for="item in selectOptions"
                    :disabled="shopMode && !item.has_any"
                    :value="item"
                >
                    {{ item.size_name }}
                    <template v-if="!reprintMode">
                        <span v-if="!item.has_any"
                            >&nbsp;-&nbsp;{{ $t('_shop._stock.soldOut') }}</span
                        >
                        <span v-else-if="!item.virtual_stock && item.quantity === 1"
                            >&nbsp;-&nbsp;{{ $tc('_shop._stock.remainX', item.quantity) }}</span
                        >
                        <span v-else-if="!item.virtual_stock && item.quantity < 4"
                            >&nbsp;-&nbsp;{{ $tc('_shop._stock.remainX', item.quantity) }}</span
                        >
                        <span v-if="product.price_min > 0">
                            &nbsp;-&nbsp;
                            <span
                                v-if="item.price_old"
                                v-html="
                                    $t('_shop._price.fromToHtml', [
                                        $f.formatMoney(item.price_old),
                                        $f.formatMoney(item.price),
                                    ])
                                "
                            />
                            <span v-else-if="item.price">{{ $f.formatMoney(item.price) }}</span>
                            <span v-else>{{ $f.formatMoney(product.price_old) }}</span>
                        </span>
                    </template>
                </option>
            </SelectField>
        </div>
        <div
            v-else-if="
                !product.is_clothing &&
                product.in_stock &&
                sizes &&
                sizes.length === 1 &&
                shopMode &&
                selectedSizeId &&
                !selectedSize.virtual_stock &&
                selectedSize.quantity > 0 &&
                selectedSize.quantity < 5
            "
            class="mt"
        >
            <div
                class="alert-info text-center text-uppercase"
                style="font-size: 12px; padding: 0.6rem"
            >
                <span v-if="selectedSize.quantity > 1"
                    >Restam {{ selectedSize.quantity }} unidades!</span
                >
                <span v-else>Última unidade!</span>
            </div>
        </div>
        <div v-else class="text-yanone">
            <p v-if="label" class="mt-0">{{ label }}</p>

            <div class="product-variations-types">
                <template v-if="fabrics && fabrics.length > 0 && isMobile">
                    <ProductVariationsFabrics
                        :product="product"
                        :shop-mode="shopMode"
                        :selected-fabric="selectedFabric"
                        :selected-size-id="selectedSizeId"
                        :sizes="sizes"
                        :fabrics="fabrics"
                        :modelings="modelings"
                        @on-fabric-click="onFabricClick"
                    />
                </template>

                <template v-if="modelings && originalModelingsCount === 1">
                    <div class="inline-block mb mt inline-fast">
                        <p class="h6 flex-child-fixed title-infos">
                            {{ $t('_product.model') }}:
                            <span class="text-bold text-no-bold-mobile">{{
                                modelings[0].name
                            }}</span>
                        </p>
                    </div>
                </template>

                <template v-else-if="modelings && modelings.length > 0">
                    <div class="inline-block mb mt inline-fast">
                        <p class="h6 flex-child-fixed title-infos">{{ $t('_product.model') }}:</p>

                        <div class="cr-option-group cr-option-modelings">
                            <nav
                                v-for="modeling in modelings"
                                :class="{
                                    'cr-option-selected': selectedModelId === modeling.id,
                                    'cr-option-crossed cr-option-disabled':
                                        shopMode && !modeling.has_any,
                                }"
                                class="cr-option flex-child-fixed mt-1 text-uppercase text-left"
                                @click="setModeling(modeling.id)"
                                v-tooltip="getModelingTooltip(modeling)"
                            >
                                {{ modeling.name }}
                            </nav>
                        </div>
                    </div>
                    <br />
                </template>

                <template v-if="sizes && (sizes.length > 1 || product.is_clothing)">
                    <div class="inline-block mb" style="width: 100%">
                        <div class="d-flex link-title-size-guide" v-if="!$scope.showSizeNotice">
                            <p class="h6 flex-child-fixed flex-xs-6 text-left title-infos mr-2">
                                {{ $t('_product.size') }}:
                            </p>
                            <p
                                v-if="product.is_clothing && shopMode"
                                class="text-right flex-xs-6"
                                :style="!isMobile ? 'margin-right: 2rem' : 'margin-right: 0.5rem'"
                            >
                                <span @click="scrollToTableSizes" style="cursor: pointer">
                                    <u><p>Ver medidas</p></u>
                                </span>
                            </p>
                        </div>

                        <div class="d-flex flex-column link-title-size-guide" v-else>
                            <p
                                class="h6 flex-child-fixed text-left title-infos"
                                :class="{ 'mb-0': product.is_clothing && shopMode }"
                            >
                                {{ $t('_product.size') }}:
                            </p>
                            <p v-if="product.is_clothing && shopMode" class="text-left mt-0">
                                <span @click="scrollToTableSizes" style="cursor: pointer">
                                    <u
                                        ><p>
                                            Nossos tamanhos mudaram desde sua última compra. Confira
                                            as novas medidas
                                        </p></u
                                    >
                                </span>
                            </p>
                        </div>

                        <nav class="cr-option-group flex-align-center cr-option-sizes">
                            <!-- todo i18n -->
                            <div
                                v-for="size in sizes"
                                :class="{
                                    'cr-option-selected':
                                        selectedSizeId && size.size_id === selectedSizeId,
                                    'cr-option-crossed cr-option-disabled':
                                        shopMode && !size.has_any,
                                }"
                                :style="{
                                    'margin-bottom': isMobile ? '1.5rem' : null,
                                }"
                                :rel="`${size.gender}-${size.size_name}`"
                                v-tooltip="getSizeTooltip(size)"
                                class="cr-option cr-option-squared flex-child-fixed text-uppercase"
                                @click="onSizeClick(size)"
                            >
                                {{ size.size_name }}
                                <div
                                    v-if="isMobile && sizeHasFewUnits(size)"
                                    class="cr-option-description"
                                >
                                    {{ $tc('_shop._stock.remainX', size.quantity) }}
                                </div>
                            </div>
                        </nav>
                    </div>
                    <br />
                </template>

                <template v-if="fabrics && fabrics.length > 0 && !isMobile">
                    <ProductVariationsFabrics
                        :product="product"
                        :shop-mode="shopMode"
                        :selected-fabric="selectedFabric"
                        :selected-size-id="selectedSizeId"
                        :sizes="sizes"
                        :fabrics="fabrics"
                        :modelings="modelings"
                        @on-fabric-click="onFabricClick"
                    />
                </template>
            </div>
        </div>

        <!-- todo i18n -->
        <div v-if="showReprintMessage" class="mt mb-3">
            <p class="text-yanone">
                <span v-if="selectedVariation && selectedVariation.fabric_id">
                    A cor
                    <strong>{{ selectedVariation.product_color_name }}</strong>
                    não está disponível no tamanho
                    <strong>{{ selectedVariation.size_name }}</strong
                    >.
                </span>
                <span v-else> O tamanho selecionado não está disponível. </span>
                <a class="text-accent text-underline" @click="$emit('reprint', selectedVariation)">
                    Me avise quando chegar!
                </a>
            </p>
        </div>

        <DeliverReadyInfoModal v-model="showDeliveryReadyInfo" />

        <!--<template v-if="modelings && modelings.length > 0" id="showDelivery">
            <div v-if="hasDeliveryReadySizes" class="showDelivery">
                <div class="d-inline-flex flex-align-center mt-3">
                    <span>
                        <input
                            id="only-delivery-ready"
                            v-model="onlyDeliveryReady"
                            class="cr-checkbox"
                            type="checkbox"
                        />
                        <label class="text-left" for="only-delivery-ready">
                            {{ $t('_product.deliveryReadyOnly') }}
                        </label>
                    </span>
                    <span @click="showDeliveryReadyInfo = true">
                        <i class="cursor-pointer icon-cr icon-question-sign ml-1"></i>
                    </span>
                </div>
                <Alert class="mt" alertClass="alert-warning" :alert="deadlineAlert" />
            </div>
        </template>-->

        <Alert
            v-if="shopMode && hasFastDelivery && !hasDeliveryReadySizes"
            class="mt"
            alertClass="alert-warning"
            :alert="fastDeliveryAlert"
        />
    </div>
</template>

<script>
    import SelectField from '../../../../../submodules/src/components/input/select-field/SelectField.vue';
    import Alert from '../common/Alert';
    import DeliverReadyInfoModal from './DeliverReadyInfoModal';
    import ProductVariationsFabrics from './ProductVariationsFabrics.vue';
    import BaseVariations from './BaseVariations.vue';
    import { EventBus, readUrlQuery, resizeOrientationAware } from '../../../common/common';

    export default {
        name: 'ProductVariations',

        extends: BaseVariations,

        props: {
            initialModelUrl: {
                type: String,
                required: false,
            },
            initialSizeName: {
                type: String,
                required: false,
            },
        },

        components: {
            ProductVariationsFabrics,
            DeliverReadyInfoModal,
            SelectField,
            Alert,
        },

        data() {
            return {
                resizeAwareRemoveCb: null,
                fastDeliveryAlert: {
                    message: 'Esse Produto poderá não chegar até o Natal',
                    timeout: 0,
                },
            };
        },

        computed: {
            originalSizes() {
                return this.product.variations.length;
            },
        },

        created() {
            let model;
            if (this.selectedVariation && this.selectedVariation.product_model_id) {
                model = this.modelings.find(
                    (m) => m.id === this.selectedVariation.product_model_id
                );
            } else if (this.product.is_clothing) {
                let modelQuery = this.initialModelUrl || readUrlQuery('model');

                if (modelQuery) {
                    model = this.modelings.find((m) => m.url === modelQuery);
                }

                if (!model) {
                    modelQuery = readUrlQuery('gender');
                    if (modelQuery && (modelQuery === 'M' || modelQuery === 'F')) {
                        model = this.modelings.find((m) => m.gender === modelQuery);
                    }
                }
            }

            if (!model && this.modelings && this.modelings.length > 0) {
                model = this.modelings.find((m) => m.has_any);
                model = model || this.modelings[0];
            }

            if (model) this.setModeling(model.id);

            if (!this.selectedModelId && this.modelings.length === 1 && this.product.is_clothing) {
                this.setModeling(this.modelings[0].id);
            }

            let fabric;
            if (this.selectedVariation && this.selectedVariation.fabric_id) {
                fabric = this.fabrics.find((c) => c.fabric_id === this.selectedVariation.fabric_id);
            } else if (this.product.is_clothing) {
                let fabricQuery = parseInt(readUrlQuery('fabric') || 0);
                if (fabricQuery) {
                    fabric = this.fabrics.find((c) => c.fabric_id === fabricQuery);
                }
            }

            if (fabric) this.setFabric(fabric.fabric_id);

            if (!this.selectedFabricId && this.fabrics.length > 0) {
                this.setFabric(this.fabrics[0].fabric_id);
            }

            let size;
            if (this.selectedVariation) {
                size = this.sizes.find((s) => s.size_id === this.selectedVariation.size_id);
            } else if (this.product.is_clothing) {
                const sizeQuery = this.initialSizeName || readUrlQuery('size');
                if (sizeQuery) {
                    size = this.sizes.find((s) => s.size_name === sizeQuery);
                }
            }

            if (size) this.setSize(size.size_id);

            if (!this.selectedSizeId && this.sizes.length === 1 && !this.product.is_clothing) {
                this.setSize(this.sizes[0].size_id);
            }
        },

        mounted() {
            this.resizeAwareRemoveCb = resizeOrientationAware(() => {
                this.isMobile = window.innerWidth < 768;
            }, true);
        },

        beforeDestroy() {
            this.resizeAwareRemoveCb();
        },

        methods: {
            getModelingTooltip(model) {
                if (this.shopMode && !model.has_any) {
                    return this.$t('_product._reprint.soldOutClick');
                }
                return null;
            },

            getSizeTooltip(size) {
                if (this.shopMode && !size.has_any) {
                    return this.$t('_product._reprint.soldOutClick');
                }
                return null;
            },

            scrollToTableSizes() {
                EventBus.$emit('open-collapside-size-table');
                cr$.byId('table-sizes-anchor').scrollIntoView();
            },

            onFabricClick(fabric) {
                this.setFabric(fabric.fabric_id);
            },

            sizeHasFewUnits(size) {
                return (
                    this.shopMode &&
                    this.selectedModelId &&
                    !size.virtual_stock &&
                    size.quantity > 0 &&
                    size.quantity < 4 &&
                    this.product.in_stock
                );
            },
        },
    };
</script>
