<template>
    <div id="advantages">
        <div
            v-if="
                $scope.IS_REGULAR_CUSTOMER &&
                !$scope.referral_token &&
                !hidePromoBanner &&
                $scope.PROMO &&
                $scope.PROMO.img_promo &&
                !$scope.view_counter &&
                !(isHome && this.$scope.PROMO.contador !== '1')
            "
        >
            <div>
                <a id="top-promo-image" :href="$scope.PROMO.banner_top_link">
                    <div v-if="$scope.PROMO.img_promo_mobile" id="top-promo-image-mobile">
                        <img
                            v-if="!isHome"
                            :alt="$scope.PROMO.img_promo_alt"
                            :data-src="`${$scope.CDN_IMAGES}/${$scope.PROMO.img_promo_mobile}?auto=compress,format&q=65`"
                            class="lazy lazy-fade hidden-xs-up img-responsive"
                            width="500"
                            height="100"
                        />
                        <div class="hidden-xs-up countdown-mobile" v-if="showCountdown">
                            <Countdown
                                :is-home="isHome"
                                :show-days="true"
                                :alt="$scope.PROMO.img_promo_alt"
                                v-if="showCountdown"
                                :deadline="this.promoEndDate"
                                class="d-block"
                            />
                        </div>
                    </div>

                    <div
                        id="top-promo-image-desktop"
                        :class="{
                            'hidden-xs-down': $scope.PROMO.img_promo_mobile,
                        }"
                    >
                        <img
                            v-if="!isHome"
                            id="top-promo-image-background"
                            :alt="$scope.PROMO.img_promo_alt"
                            :data-src="`${$scope.CDN_IMAGES}/${$scope.PROMO.img_promo_fundo}?auto=compress,format&q=65`"
                            class="lazy lazy-fade"
                        />
                        <div
                            id="top-promo-image-foreground"
                            class="d-flex"
                            :style="
                                'background-color: ' +
                                ($scope.active_counter && $scope.active_counter.length > 0
                                    ? $scope.active_counter[0].counter_background_color
                                    : '') +
                                ' !important'
                            "
                        >
                            <div v-if="isHome">
                                <span
                                    class="promo-button-ab2 pr-3"
                                    :style="
                                        'color: ' +
                                        ($scope.active_counter && $scope.active_counter.length > 0
                                            ? $scope.active_counter[0].counter_color
                                            : '') +
                                        ' !important'
                                    "
                                    >Confira agora</span
                                >
                            </div>
                            <Countdown
                                :is-home="isHome"
                                :show-days="true"
                                v-if="showCountdown"
                                :deadline="this.promoEndDate"
                                class="d-block"
                            />
                            <div
                                v-if="isHome"
                                class="promo-text-ab2 pl-3"
                                :style="
                                    'color: ' +
                                    ($scope.active_counter && $scope.active_counter.length > 0
                                        ? $scope.active_counter[0].counter_color
                                        : '') +
                                    ' !important'
                                "
                            >
                                {{ $scope.PROMO.img_promo_alt }}
                            </div>
                            <img
                                v-if="!isHome"
                                :alt="$scope.PROMO.img_promo_alt"
                                :data-src="`${$scope.CDN_IMAGES}/${$scope.PROMO.img_promo}?auto=compress,format&q=65`"
                                class="lazy lazy-fade img-responsive"
                                data-position="topo-site"
                                width="1250"
                                height="60"
                            />
                        </div>
                    </div>
                </a>

                <div
                    v-if="$scope.regulamento && !hideRegulation"
                    class="botao-regulamento text-center cursor-pointer text-uppercase text-yanone text-underline"
                    @click="showRegulamento = true"
                >
                    Confira o regulamento
                </div>

                <Modal
                    v-if="$scope.regulamento"
                    v-model="showRegulamento"
                    class="modal-regulamento"
                >
                    <div>
                        <div class="cr-modal-header">
                            <img
                                v-if="$scope.PROMO.bannerRules"
                                :data-src="`${$scope.CDN_IMAGES}/images/site/promos/${$scope.PROMO.bannerRules}?auto=compress,format&q=65`"
                                class="lazy lazy-fade banner-regulamento"
                                alt="Banner do regulamento"
                            />
                        </div>
                    </div>
                    <div class="card-block">
                        <h2>Regulamento</h2>
                        <div v-html="$scope.regulamento"></div>
                    </div>
                    <div class="card-block text-right">
                        <button class="btn" type="button" @click="showRegulamento = false">
                            Fechar
                        </button>
                    </div>
                </Modal>

                <CountdownFixed
                    :deadline="promoCounterEndDate"
                    :offer-title="promoCounterParams.text"
                    :link="promoCounterParams.link"
                    :discount="promoCounterParams.discount"
                    :params="promoCounterParams"
                    :view-button="true"
                    :poupup="true"
                    class="d-block"
                    v-if="promoCounterParams && !showCountdownCookie && !showCountdown"
                />
            </div>
        </div>

        <div v-else-if="$scope.referral_token">
            <a href="cadastrar">
                <picture>
                    <source
                        :srcset="`${$scope.CDN_IMAGES}/images/site/2022/indica/bnr_mobile_indica_240821.png?auto=compress,format&q=65`"
                        media="(max-width: 768px)"
                    />
                    <img
                        :data-src="`${$scope.CDN_IMAGES}/images/site/2022/indica/bnr_desktop_indica_240821.png?auto=compress,format&q=65`"
                        alt="Indica Chico Rei"
                        class="lazy img-responsive center-block"
                    />
                </picture>
            </a>
        </div>
        <div
            v-else-if="
                $scope.IS_REGULAR_CUSTOMER &&
                !hidePromoBanner &&
                !$scope.referral_token &&
                showCountDownOthers
            "
        >
            <CountdownFixed
                :deadline="promoCounterEndDate"
                :offer-title="promoCounterParams.text"
                :link="promoCounterParams.link"
                :discount="promoCounterParams.discount"
                :params="promoCounterParams"
                :view-button="true"
                :poupup="true"
                class="d-block"
                v-if="promoCounterParams"
            />

            <!--            <CountdownFixed-->
            <!--                :deadline="promoShippingEndDate"-->
            <!--                :offer-title="paramsShippingCounter.text"-->
            <!--                :link="paramsShippingCounter.link"-->
            <!--                :discount="paramsShippingCounter.discount"-->
            <!--                :params="paramsShippingCounter"-->
            <!--                :view-button="false"-->
            <!--                :poupup="false"-->
            <!--                class="d-block"-->
            <!--                v-if="paramsShippingCounter"-->
            <!--            />-->
        </div>
        <div
            v-else-if="
                $scope.IS_REGULAR_CUSTOMER &&
                !$scope.referral_token &&
                !hidePromoBanner &&
                promoCounterParams
            "
        >
            <!--            <CountdownFixed-->
            <!--                :deadline="promoCounterEndDate"-->
            <!--                :offer-title="promoCounterParams.text"-->
            <!--                :link="promoCounterParams.link"-->
            <!--                :discount="promoCounterParams.discount"-->
            <!--                :params="promoCounterParams"-->
            <!--                :view-button="true"-->
            <!--                :poupup="true"-->
            <!--                class="d-block"-->
            <!--                v-if="promoCounterParams && !showCountdownCookie && !showCountdown"-->
            <!--            />-->
        </div>

        <!--        <CountdownFixed-->
        <!--            :deadline="promoShippingEndDate"-->
        <!--            :offer-title="paramsShippingCounter.text"-->
        <!--            :link="paramsShippingCounter.link"-->
        <!--            :discount="paramsShippingCounter.discount"-->
        <!--            :params="paramsShippingCounter"-->
        <!--            :view-button="false"-->
        <!--            :poupup="true"-->
        <!--            class="d-block"-->
        <!--            v-if="paramsShippingCounter"-->
        <!--        />-->

        <CountdownFixed
            :deadline="promoDaybreakEndDate"
            :offer-title="paramsDaybreakCounter.text"
            :link="paramsDaybreakCounter.link"
            :discount="paramsDaybreakCounter.discount"
            :params="paramsDaybreakCounter"
            :view-button="false"
            :poupup="true"
            class="d-block"
            v-if="paramsDaybreakCounter"
        />
    </div>
</template>

<script>
    import { EventBus, readCookie, timeHandler, updateLazyLoad } from '../../../common/common';
    import Countdown from './Countdown';
    import Modal from './container/Modal';
    import Contador from '../contadores/Contador.vue';
    import CountdownFixed from './CountdownFixed.vue';

    export default {
        name: 'Advantages',
        components: { CountdownFixed, Contador, Countdown, Modal },
        props: ['hidePromoBanner', 'hideRegulation', 'isHome'],

        data() {
            return {
                promoStartDate: timeHandler(
                    this.$scope.PROMO ? this.$scope.PROMO.data_inicio : ''
                ).toDate(),
                promoEndDate: timeHandler(
                    this.$scope.PROMO ? this.$scope.PROMO.data_fim : ''
                ).toDate(),
                promoCounterEndDate: timeHandler(
                    this.$scope.PROMO_COUNTER ? this.$scope.params_promo_counter.end_time : ''
                ).toDate(),

                promoShippingEndDate: timeHandler(
                    this.$scope.params_shipping_counter
                        ? this.$scope.params_shipping_counter.end_time
                        : ''
                ).toDate(),

                promoDaybreakEndDate: timeHandler(
                    this.$scope.params_daybreak_counter
                        ? this.$scope.params_daybreak_counter.end_time
                        : ''
                ).toDate(),

                promoCounterParams: this.$scope.params_promo_counter,
                paramsShippingCounter: this.$scope.params_shipping_counter,
                paramsDaybreakCounter: this.$scope.params_daybreak_counter,

                showRegulamento: false,
                showCovidInfo: false,
            };
        },

        mounted() {
            updateLazyLoad();
        },

        created() {
            EventBus.$on('open-regulamento', () => (this.showRegulamento = true));
        },

        computed: {
            showCountdown() {
                if (
                    !this.$scope.PROMO ||
                    this.$scope.PROMO.contador !== '1' ||
                    !this.promoStartDate ||
                    !this.promoEndDate
                ) {
                    return false;
                }

                const now = new Date();
                const diff = this.promoEndDate.getTime() - now.getTime();
                return (
                    this.promoStartDate < now &&
                    this.promoEndDate > now &&
                    diff < 4 * 24 * 3600 * 1000
                );
            },

            showCountDownOthers() {
                if (
                    !this.$scope.view_counter ||
                    !this.$scope.PROMO_COUNTER ||
                    !this.$scope.params_promo_counter.end_time ||
                    !this.promoCounterEndDate
                ) {
                    return false;
                }

                const now = new Date();
                const diff = this.promoCounterEndDate.getTime() - now.getTime();
                return this.promoCounterEndDate > now && diff < 4 * 24 * 3600 * 1000;
            },
            showCountdownCookie() {
                const poupup = readCookie('counter_popup');
                return poupup === '1';
            },
        },
    };
</script>
