import { EventBus } from './common';
import cr$ from './crquery';
import { CLOSE_DESKTOP_MENU_EVENT, SHOW_MOBILE_MENU_EVENT } from '../vue/events';
import throttle from 'lodash/throttle';
import Vue from '../vue/common/vue-common';
import HeaderAdvantagesSlider from '../vue/components/header/HeaderAdvantagesSlider.vue';
import HeaderFastDelivery from '../vue/components/header/HeaderFastDelivery.vue';
import PopupManager from '../vue/components/Popups/PopupManager.vue';

const initHeader = () => {
    const desktopHeaderEl = cr$.byId('header-desktop');
    if (desktopHeaderEl.exists()) {
        window.addEventListener(
            'scroll',
            throttle(
                () => {
                    EventBus.$emit(CLOSE_DESKTOP_MENU_EVENT);
                },
                500,
                {
                    leading: true,
                    trailing: true,
                }
            ),
            { passive: true }
        );
    }

    const headerDrawerEl = cr$.byId('header-drawer-icon');
    if (headerDrawerEl.exists()) {
        // open drawer on icon click
        headerDrawerEl.click(() => EventBus.$emit(SHOW_MOBILE_MENU_EVENT));
    }
};

cr$.ready(function () {
    requestIdleCallback(() => {
        initHeader();
    });

    requestIdleCallback(() => {
        if (cr$.byId('home-advantages-slider').exists()) {
            new Vue({
                el: '#home-advantages-slider',
                template: `<HeaderAdvantagesSlider />`,
                components: { HeaderAdvantagesSlider },
            });
        }
    });

    requestIdleCallback(() => {
        if (cr$.byId('fast-delivery-header').exists()) {
            new Vue({
                el: '#fast-delivery-header',
                template: `<HeaderFastDelivery />`,
                components: { HeaderFastDelivery },
            });
        }
    });

    requestIdleCallback(() => {
        if (cr$.byId('popup-manager-header').exists()) {
            new Vue({
                el: '#popup-manager-header',
                template: `<PopupManager />`,
                components: { PopupManager },
            });
        }
    });
});
