<script>
    import ProductCategories from '../ProductCategories';
    import { EventBus } from '../../../../common/common';

    export default {
        name: 'ProductAbout',

        components: { ProductCategories },

        props: {
            product: {
                type: Object,
                required: true,
            },
            showProductVideo: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                selectedColorId: this.product.color.id,
                showVideo: false,
            };
        },

        created() {
            EventBus.$on('selected-color-change', (c) => (this.selectedColorId = c.color_id));
        },

        computed: {
            hasDescription() {
                return !!(this.product.description || '').trim();
            },

            title() {
                if (
                    this.product.categories.data &&
                    this.product.categories.data.some((c) => c.id === 255)
                ) {
                    return this.$t('about');
                }

                return this.$t('_product.aboutPrint');
            },

            aboutText() {
                if (this.product.type.id === 1) {
                    if (
                        this.product.color.id === this.selectedColorId ||
                        this.product.categories.some((c) => c.id === 133)
                    )
                        return this.product.info;

                    if (this.product.categories.some((c) => c.id === 255)) {
                        // Básica
                        if (this.selectedColorId === 13) {
                            // Mescla
                            return `
                            <p>Nossas camisetas mescla são produzidas com malha 88% algodão e 12% poliéster. O algodão é trazido de plantações do nordeste brasileiro, tingidos e tecidos no sul do país, e produzidos artesanalmente em Minas Gerais. A preocupação com o que vestimos parte desde o momento do plantio, em produção 100% consciente, com certificado de algodão sustentável.</p>
                            <p>A malha chega em nossa produção e recebe um merecido descanso, aguardando pelo menos 24h na mesa de corte, para que já ocorra ali o encolhimento natural das fibras. O algodão super-macio é garantido por um processo com casca de arroz, reutilizando o material que seria descartado na natureza. Esse processo não envolve qualquer agente químico artificial. A tonalidade da malha pode apresentar pequenas diferenças com relação à foto por conta da iluminação e variação de monitores. Todas as nossas camisetas são veganas, atestadas pelo selo "PETA - Cruelty Free".</p>`;
                        } else if (this.selectedColorId === 3) {
                            // Branca
                            return `
                            <p>Nossas camisetas são produzidas com fios 100% naturais de algodão. Esse material é trazido de plantações do nordeste brasileiro, tingidos e tecidos no sul do país, e produzidos artesanalmente em Minas Gerais. A preocupação com o que vestimos parte desde o momento do plantio, em produção 100% consciente, com certificado de algodão sustentável. Por conta do processo de produção de todas as camisetas brancas, ela pode dar a impressão de ser menos encorpada do que malhas coloridas. Porém, qualidade e durabilidade são exatamente iguais.</p>
                            <p>A malha chega em nossa produção e recebe um merecido descanso, aguardando pelo menos 24h na mesa de corte, para que já ocorra ali o encolhimento natural das fibras. O algodão super-macio é garantido por um processo com casca de arroz, reutilizando o material que seria descartado na natureza. Esse processo não envolve qualquer agente químico artificial. A tonalidade da malha pode apresentar pequenas diferenças com relação à foto por conta da iluminação e variação de monitores. Todas as nossas camisetas são veganas, atestadas pelo selo "PETA - Cruelty Free".</p>`;
                        } else {
                            return `
                            <p>Nossas camisetas são produzidas com fios 100% naturais de algodão. Esse material é trazido de plantações do nordeste brasileiro, tingidos e tecidos no sul do país, e produzidos artesanalmente em Minas Gerais. A preocupação com o que vestimos parte desde o momento do plantio, em produção 100% consciente, com certificado de algodão sustentável.</p>
                            <p>A malha chega em nossa produção e recebe um merecido descanso, aguardando pelo menos 24h na mesa de corte, para que já ocorra ali o encolhimento natural das fibras. O algodão super-macio é garantido por um processo com casca de arroz, reutilizando o material que seria descartado na natureza. Esse processo não envolve qualquer agente químico artificial. A tonalidade da malha pode apresentar pequenas diferenças com relação à foto por conta da iluminação e variação de monitores. Todas as nossas camisetas são veganas, atestadas pelo selo "PETA - Cruelty Free".</p>`;
                        }
                    }
                    if ([1, 3, 5, 6, 9, 10, 11].indexOf(this.product.production_type) >= 0) {
                        // Estamparia
                        if (this.selectedColorId === 13) {
                            // Mescla
                            return `
                            <ul>
                                <li>Camiseta feita artesanalmente com malha 88% algodão e 12% poliéster, refinada e penteada;</li>
                                <li>Estampada em processo de serigrafia com tinta a base d'água e padrão de cores exclusivo;</li>
                            </ul>`;
                        } else if (this.selectedColorId === 3) {
                            // Branca
                            return `
                            <ul>
                                <li>Camiseta com 100% de fibra natural de algodão sustentável</li>
                                <li>Estampada em processo de serigrafia</li>
                            </ul>
                            `;
                        } else {
                            return `
                            <ul>
                                <li>Camiseta com 100% de fibra natural de algodão sustentável</li>
                                <li>Estampada em processo de serigrafia</li>
                            </ul>`;
                        }
                    }

                    if ([2, 4, 7].indexOf(this.product.production_type) >= 0) {
                        // Impressora
                        if (this.selectedColorId === 13) {
                            // Mescla
                            return `
                            <ul>
                                <li>Camiseta com malha 88% algodão e 12% poliéster</li>
                                <li>Estampada com impressão digital</li>
                            </ul>`;
                        } else if (this.selectedColorId === 3) {
                            // Branca
                            return `
                            <ul>
                                <li>Camiseta com 100% de fibra natural de algodão sustentável</li>
                                <li>Por conta do processo de produção de todas as camisetas brancas, ela pode dar a impressão de ser menos encorpada do que malhas coloridas. Porém, qualidade e durabilidade são exatamente iguais</li>
                                 <li>Estampada com impressão digital</li>
                            </ul>`;
                        } else {
                            return `
                            <ul>
                                <li>Camiseta com 100% de fibra natural de algodão sustentável</li>
                                <li>Estampada com impressão digital</li>
                            </ul>`;
                        }
                    }

                    return this.product.info;
                } else if (this.product.info) {
                    return this.product.info;
                } else {
                    switch (this.product.type.url) {
                        case 'poster':
                            return `
                            <p>Impressão em Papel Fotográfico Fosco  260g</p>`;
                        case 'lenco':
                            return `
                            <p>Dimensões: 80x80cm (pode haver pequena variação de tamanho);</p>
                            <p>Tecido: Voil 100% poliester;</p>
                            <p>Estampa: sublimação.</p>`;
                        case 'bandanas-para-cachorro':
                            return `
                            <p>Carinho também para os bichinhos! Aqui na Chico somos pet friendly desde sempre e não podíamos deixar de desenvolver uma bandana toda lindona</p>
                            <p>100% poliéster pensada pra deixar seu amiguinho mais elegante.</p>`;
                        case 'necessaire-de-tecido':
                            return `<p>Nossa necessaire de tecido 100% poliéster cabe o que tiver em mente! Pode ser um estojo pra levar na faculdade, aquela bolsa esperta de viagem, e até levar aquele make que não ficamos sem. Chaves? Pode também. Não há limites pra criatividade, estilo e carregamento de bugigangas.</p>`;
                        default:
                            return null;
                    }
                }
            },
        },
    };
</script>
