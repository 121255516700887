<template>
    <div class="form-intro">
        <h2 class="form-title">{{ intro.title }}</h2>
        <p class="form-description">
            {{ intro.description }}
        </p>
    </div>
</template>

<script>
    export default {
        name: 'FormIntro',
        data() {
            return {
                intro: {
                    title: 'Atendimento por E-mail',
                    description:
                        'Preencha o formulário abaixo para nos enviar sua mensagem. Não se esqueça de conferir o campo de e-mail, pois é o canal onde você receberá nosso retorno.',
                },
            };
        },
    };
</script>
