var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"contador-timer-clock"}},[_c('div',{staticClass:"clock-section",attrs:{"id":"hours"}},[_c('span',{staticClass:"clock-number",style:({
                'background-color': _vm.counterBackgroundColor,
                color: _vm.counterTextColor,
            })},[_vm._v(_vm._s(_vm.hours >= 10 ? _vm.hours.toString().substring(0, 1) : 0))]),_vm._v(" "),_c('span',{staticClass:"clock-number",style:({
                'background-color': _vm.counterBackgroundColor,
                color: _vm.counterTextColor,
            })},[_vm._v(_vm._s(_vm.hours >= 10
                    ? _vm.hours.toString().substring(1, 2)
                    : _vm.hours.toString().substring(0, 1)))]),_vm._v(" "),_c('span',{staticClass:"clock-marker",style:({
                color: _vm.counterTextColor,
            })},[_vm._v("h")])]),_vm._v(" "),_c('div',{staticClass:"clock-section",attrs:{"id":"minutes"}},[_c('span',{staticClass:"clock-number",style:({
                'background-color': _vm.counterBackgroundColor,
                color: _vm.counterTextColor,
            })},[_vm._v(_vm._s(_vm.minutes >= 10 ? _vm.minutes.toString().substring(0, 1) : 0))]),_vm._v(" "),_c('span',{staticClass:"clock-number",style:({
                'background-color': _vm.counterBackgroundColor,
                color: _vm.counterTextColor,
            })},[_vm._v(_vm._s(_vm.minutes >= 10
                    ? _vm.minutes.toString().substring(1, 2)
                    : _vm.minutes.toString().substring(0, 1)))]),_vm._v(" "),_c('span',{staticClass:"clock-marker",style:({
                color: _vm.counterTextColor,
            })},[_vm._v("m")])]),_vm._v(" "),_c('div',{staticClass:"clock-section",attrs:{"id":"seconds"}},[_c('span',{staticClass:"clock-number",style:({
                'background-color': _vm.counterBackgroundColor,
                color: _vm.counterTextColor,
            })},[_vm._v(_vm._s(_vm.seconds >= 10 ? _vm.seconds.toString().substring(0, 1) : 0))]),_vm._v(" "),_c('span',{staticClass:"clock-number",style:({
                'background-color': _vm.counterBackgroundColor,
                color: _vm.counterTextColor,
            })},[_vm._v(_vm._s(_vm.seconds >= 10
                    ? _vm.seconds.toString().substring(1, 2)
                    : _vm.seconds.toString().substring(0, 1)))]),_vm._v(" "),_c('span',{staticClass:"clock-marker",style:({
                color: _vm.counterTextColor,
            })},[_vm._v("s")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }