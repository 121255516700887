<template>
    <div class="success-form mt-3">
        <p class="alert-success mb-3">Mensagem enviada com sucesso, obrigado!</p>
        <p>Dados da sua solicitação:</p>
        <ul>
            <li>
                <div class="data-section">
                    <span class="title">Assunto:</span>
                    <span class="message-content">{{ this.formStatus.get('assunto') }}</span>
                </div>
            </li>
            <li
                v-if="
                    formStatus.get('subcategoria') !== 'null' &&
                    formStatus.get('subcategoria') !== ''
                "
            >
                <div class="data-section">
                    <span class="title">Categoria:</span>
                    <span class="message-content">{{ this.formStatus.get('subcategoria') }}</span>
                </div>
            </li>
            <li v-if="formStatus.get('escola')">
                <div class="data-section">
                    <span class="title">Escola:</span>
                    <span class="message-content">{{ this.formStatus.get('escola') }}</span>
                </div>
            </li>
            <li v-if="formStatus.get('filial')">
                <div class="data-section">
                    <span class="title">Filial:</span>
                    <span class="message-content">{{ this.formStatus.get('filial') }}</span>
                </div>
            </li>
            <li>
                <div class="data-section">
                    <span class="title">Nome:</span>
                    <span class="message-content">{{ this.formStatus.get('nome') }}</span>
                </div>
            </li>
            <li>
                <div class="data-section">
                    <span class="title">E-mail:</span>
                    <span class="message-content">{{ this.formStatus.get('email') }}</span>
                </div>
            </li>
            <li v-if="formStatus.get('cidade') !== 'null' && formStatus.get('cidade') !== ''">
                <div class="data-section">
                    <span class="title">Cidade:</span>
                    <span class="message-content">{{ this.formStatus.get('cidade') }}</span>
                </div>
            </li>
            <li>
                <div class="data-section">
                    <span class="title">CPF:</span>
                    <span class="message-content">{{ this.formStatus.get('cpf') }}</span>
                </div>
            </li>
            <li>
                <div class="data-section">
                    <span class="title">Número do Pedido:</span>
                    <span class="message-content">{{ this.formStatus.get('pedido') }}</span>
                </div>
            </li>
            <li>
                <div class="data-section">
                    <span class="title">Mensagem:</span>
                    <span class="message-content">{{ this.formStatus.get('mensagem') }}</span>
                </div>
            </li>
        </ul>
        <button class="btn" id="btn-return-form" @click="returnToForm">Voltar</button>
    </div>
</template>

<script>
    export default {
        name: 'SuccessFormMessage',
        props: {
            returnToForm: {
                type: Function,
                default: () => {},
            },
            formStatus: {
                default: () => new FormData(),
            },
        },

        created() {
            console.log(this.formStatus.get('escola') !== null);
        },
    };
</script>
