<template>
    <div :style="{ height: '78px' }">
        <vue-recaptcha
            :sitekey="gRecaptchaKey"
            @verify="onRecaptchaVerify"
            @expired="onRecaptchaExpired"
        />
        <input type="hidden" name="g-recaptcha-response" v-model="recaptchaResponse" />
    </div>
</template>

<script>
    import { VueRecaptcha } from 'vue-recaptcha';

    export default {
        name: 'FormCaptcha',
        components: {
            VueRecaptcha,
        },
        props: {
            recaptchaResponse: {
                type: String,
                default: '',
            },
            isRecaptchaVerified: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                gRecaptchaKey: '6Ld-GRMUAAAAABpupP5l5O3IdiEALCtwnja6wqf7',
            };
        },
        methods: {
            onRecaptchaVerify(response) {
                this.$emit('update:recaptchaResponse', response);
                this.$emit('update:isRecaptchaVerified', true);
            },
            onRecaptchaExpired() {
                this.$emit('update:recaptchaResponse', '');
                this.$emit('update:isRecaptchaVerified', false);
            },
        },
    };
</script>
